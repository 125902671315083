exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-checkout-index-tsx": () => import("./../../../src/templates/Checkout/index.tsx" /* webpackChunkName: "component---src-templates-checkout-index-tsx" */),
  "component---src-templates-my-account-tsx": () => import("./../../../src/templates/MyAccount.tsx" /* webpackChunkName: "component---src-templates-my-account-tsx" */),
  "component---src-templates-new-pages-home-index-tsx": () => import("./../../../src/templates/newPages/Home/index.tsx" /* webpackChunkName: "component---src-templates-new-pages-home-index-tsx" */),
  "component---src-templates-new-pages-institutional-page-index-tsx": () => import("./../../../src/templates/newPages/InstitutionalPage/index.tsx" /* webpackChunkName: "component---src-templates-new-pages-institutional-page-index-tsx" */),
  "component---src-templates-new-pages-product-details-index-tsx": () => import("./../../../src/templates/newPages/ProductDetails/index.tsx" /* webpackChunkName: "component---src-templates-new-pages-product-details-index-tsx" */),
  "component---src-templates-not-listed-products-not-listed-products-tsx": () => import("./../../../src/templates/NotListedProducts/NotListedProducts.tsx" /* webpackChunkName: "component---src-templates-not-listed-products-not-listed-products-tsx" */),
  "component---src-templates-terms-and-privacy-terms-and-privacy-tsx": () => import("./../../../src/templates/TermsAndPrivacy/TermsAndPrivacy.tsx" /* webpackChunkName: "component---src-templates-terms-and-privacy-terms-and-privacy-tsx" */)
}

